@import '@core/styles/vars';
@import '@core/styles/mixins/dark-mode';

.SearchResults {
  --Search-icon-offset: 8px;

  $block: &;

  display: flex;
  flex: 1 1 auto;
  flex-flow: nowrap column;
  overflow-y: auto;
  padding: 0 var(--md);

  &_is-searchbert {
    font-size: 13px;
    padding: 0;
  }

  // FF ignores padding-bottom on scrollable containers https://bugzilla.mozilla.org/show_bug.cgi?id=748518
  &:not(.SearchResults_is-searchbert)::after {
    content: '';
    display: block;
    flex: 0 0 auto;
    height: var(--md);
    width: 100%;
  }

  &_empty {
    align-items: center;
    color: var(--SuperHubSearch-empty-color);
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: center;
    overflow-wrap: anywhere;
    padding: 80px 40px;
    text-align: center;

    :global(.Title) {
      margin-top: 0;
    }
  }

  :global(.icon) {
    color: var(--project-color-primary);
    margin-bottom: 10px;

    :global(.ThemeContext_dark) & {
      @include dark-mode {
        color: var(--SuperHubSearch-empty-color);
      }
    }
  }

  &-scroll-to {
    flex: 0 0 auto;
    height: var(--sm);
  }

  &-list {
    display: flex;
    flex-direction: column;
  }

  &-result {
    $block: &;

    all: unset;
    border-radius: var(--border-radius);
    box-sizing: border-box;
    cursor: pointer;
    display: grid;
    grid-template-columns: auto 1fr;
    margin-top: 2px;
    min-height: 51px;
    padding: 9px 10px;
    text-decoration: none;

    &:hover,
    &:focus {
      background: var(--SuperHubSearch-result-background-hover);
      color: var(--SuperHubSearch-color);
    }

    &:active {
      background: var(--SuperHubSearch-result-background-active);
      color: var(--SuperHubSearch-result-color-active);

      #{$block}-icon {
        color: var(--SuperHubSearch-result-color-active);
      }

      #{$block}-project,
      #{$block}-link {
        opacity: 0.5;
      }
    }

    &:focus {
      outline: none;
    }

    &:last-child {
      margin-bottom: var(--xs);
    }

    &-text {
      max-width: 100%;
      overflow: hidden;
    }

    &-icon {
      align-items: center;
      display: flex;
      font-size: var(--icon-md);
      height: var(--icon-md);
      justify-content: center;
      margin-right: 10px;
      margin-top: var(--Search-icon-offset, 8px);
      opacity: var(--SuperHubSearch-result-icon-opacity);
      width: var(--icon-md);
    }

    &-header {
      align-items: center;
      display: flex;
      grid-area: description;
    }

    &-excerpt {
      color: var(--Search-color-excerpt);

      /* this is intentional; for ellipsis after 2 liens */
      /* stylelint-disable-next-line value-no-vendor-prefix */
      display: -webkit-box;
      flex-direction: column;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      overflow: hidden;

      p {
        margin: 0;
      }

      // This should match .ais-Snippet-highlighted styling for text matches in excerpts
      &-match-highlight {
        font-style: normal;
        position: relative;

        &::after {
          background: var(--project-color-primary, get(color 'blue'));
          border-radius: 2px;
          bottom: 0;
          content: '';
          display: block;
          height: 2px;
          left: 0;
          position: absolute;
          right: 0;
        }
      }
    }

    &-excerpt:empty {
      display: none;
    }

    &-title {
      align-items: baseline;
      display: flex;
      font-weight: 600;

      &:only-child {
        margin-top: var(--Search-icon-offset, 8px)
      }
    }

    &-link {
      font-size: 11px;
      margin-left: 5px;
      margin-right: 5px;
      opacity: 0.5;
    }

    &-project {
      font-size: 13px;
      font-weight: 400;
      margin-left: 5px;
      opacity: 0.5;
      white-space: nowrap;
    }

  }

  // methods
  &-method {
    margin-right: 5px;
  }
}
