@import '~@core/styles/vars.compat';
@import '~@core/styles/mixins/dark-mode';

.SearchBox {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  margin: 20px;
  position: relative;

  &-Input {
    @include dark-mode {
      color-scheme: dark;
    }

    flex: 1 1 auto;
    padding-left: 36px !important;

    &_loading[type='search'] {
      background-image: none;
    }

    &:focus + .SearchBox-Search {
      color: var(--project-color-primary, get(color 'blue'));
    }

    // remove safari search padding
    &::-webkit-search-decoration {
      appearance: none;
    }
  }

  &-Spinner {
    bottom: 0;
    color: get(color 'chalk');
    left: 12px;
    margin-bottom: auto;
    margin-top: auto;
    position: absolute;
    top: 0;
  }

  &-Spinner {
    animation: spin 0.5s infinite linear;
    border: 2px solid #fff;
    border-bottom-color: currentcolor;
    border-left-color: currentcolor;
    border-radius: 14px;
    border-right-color: transparent;
    border-top-color: transparent;
    content: '';
    display: block;
    height: 14px;
    pointer-events: none;
    width: 14px;

    @keyframes spin {
      0% {
        transform: rotate(0);
      }

      100% {
        transform: rotate(1turn);
      }
    }
  }
}
