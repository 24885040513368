@import '@Hub/Header/style.module';

.VersionDropdown {
  align-items: center;
  color: var(--Header-button-color);
  display: flex;
  font-weight: var(--font-weight-bold);

  &-item {
    overflow: hidden;

    &-content {
      white-space: nowrap;
      width: 100%;
    }

    &-label {
      flex: 1 1 auto;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
