/* stylelint-disable custom-property-pattern */
@import '@core/styles/vars';
@import '~@core/styles/mixins/dark-mode';

.rm-Header {
  --Header-background: var(--color-primary);
  --Header-border-color: #{rgba(black, 0.1)};
  --Header-border-width: 1px;
  --Header-button-color: var(--gray20);
  --Header-button-hover: #{rgba(black, 0.1)};
  --Header-button-active: #{$blue};
  --Header-button-focus: #{rgba($blue, 0.25)};
  --Header-jumpTo-background: var(--color-primary-inverse);
  --Header-jumpTo-color: var(--color-primary);
  --Header-logo-height: var(--header-logo-height);

  .ThemeContext_line & {
    --Header-background: var(--color-bg-page);
    --Header-border-color: var(--color-primary);
    --Header-border-width: 4px;
    --Header-button-active: var(--color-link-primary);
    --Header-button-focus: var(--color-link-primary-alpha-25);
    --Header-jumpTo-color: var(--color-link-primary);

    @include dark-mode {
      --Header-button-color: var(--white);
      --Header-button-hover: #{rgba(white, 0.1)};
      --Header-button-active: #{$blue};
      --Header-button-focus: #{rgba($blue, 0.25)};
    }
  }

  .ThemeContext_line.ThemeContext_dark &,
  .ThemeContext_line.ThemeContext_light & {
    @include dark-mode {
      --Header-button-color: var(--white);
    }
  }

  .ThemeContext_dark:not(.ThemeContext_line) & {
    --Header-button-color: var(--white);
    --Header-button-hover: #{rgba(white, 0.1)};
    --Header-button-active: #{rgba(white, 0.75)};
    --Header-button-focus: #{rgba(white, 0.075)};
  }

  &_gradient {
    background: linear-gradient(to bottom, var(--color-primary), var(--color-primary-alt));
  }

  &_overlay {
    background-position: center center;

    &-triangles {
      background-image: url('../../../../../public/img/bgs/triangles.png');
    }

    &-blueprint {
      background-image: url('../../../../../public/img/bgs/blueprint.png');
    }

    &-grain {
      background-image: url('../../../../../public/img/bgs/grain.png');
    }

    &-map {
      background-image: url('../../../../../public/img/bgs/map.png');
    }

    &-circuits {
      background-image: url('../../../../../public/img/bgs/circuits.png');
    }
  }

  &_custom {
    background-position: center center;
    background-repeat: no-repeat;

    &-pos-top-left {
      background-position: top left;
    }

    &-pos-top-center {
      background-position: top center;
    }

    &-pos-top-right {
      background-position: top right;
    }

    &-pos-center-left {
      background-position: center left;
    }

    &-pos-center-center {
      background-position: center center;
    }

    &-pos-center-right {
      background-position: center right;
    }

    &-pos-bottom-left {
      background-position: bottom left;
    }

    &-pos-bottom-center {
      background-position: bottom center;
    }

    &-pos-bottom-right {
      background-position: bottom right;
    }

    &-size-auto {
      background-repeat: no-repeat;
    }

    &-size-tile {
      background-repeat: repeat;
    }

    &-size-tile-x {
      background-repeat: repeat-x;
    }

    &-size-tile-y {
      background-repeat: repeat-y;
    }

    &-size-contain {
      background-repeat: no-repeat;
      background-size: contain;
    }

    &-size-cover {
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
}

.rm-ThemeToggle,
.rm-Header-top-link,
.rm-Header-bottom-link {
  border: var(--border-width) solid transparent;
  font-size: 1em;
  height: $button-sm;
  padding: $sm;

  .ThemeContext_dark &,
  .ThemeContext_line.ThemeContext_light & {
    color: var(--Header-button-color);
  }

  // repeat to override button
  &.Button {
    > i {
      font-size: var(--button-font-size);
    }

    &:hover,
    &:active,
    &:focus-within {
      color: var(--Header-button-color);
    }

    &:hover {
      /* specific to override dark-mode buttons */
      @include dark-mode {
        border-color: var(--Header-button-hover);
      }

      border-color: var(--Header-button-hover);
    }

    &:active,
    &:focus-within {
      /* specific to override dark-mode buttons */
      @include dark-mode {
        border-color: var(--Header-button-active);
      }

      background: transparent;
      border-color: var(--Header-button-active);
      box-shadow: 0 0 0 3px var(--Header-button-focus);
      outline: none;
    }
  }

  &_active {
    border: var(--border-width) solid transparent;
  }

  &_active,
  &_active:hover,
  &_active:active,
  &.active {
    background: var(--Header-button-hover);
  }

  &_active:hover {
    border-color: var(--Header-button-hover);
  }

  &_active:active,
  &_active:focus {
    background: transparent;
    border-color: var(--Header-button-active);
    box-shadow: 0 0 0 3px var(--Header-button-focus);
  }
}

// large logos
.rm-Logo-img_lg {
  --Header-logo-height: 40px;
}
