@import '@core/styles/vars';
@import '@ui/Flyout/style.module';

// button to toggle the nav
.menu {
  align-items: center;
  background: none;
  border: var(--border-width) solid transparent;
  border-radius: var(--border-radius);
  color: var(--Header-button-color);
  display: flex;
  font-size: var(--icon-lg);
  height: $button-sm;
  justify-content: center;
  margin-right: $sm;
  padding: 0;
  transition: box-shadow var(--transition-fast) var(--transition-timing);
  width: $button-sm;

  &:hover {
    border-color: var(--Header-button-hover);
  }

  &:active,
  &:focus {
    border-color: var(--Header-button-active);
    box-shadow: 0 0 0 3px var(--Header-button-focus);
  }

  &:focus {
    outline: 0;
  }
}

.MobileSubnav {
  border: 1px solid;
  border-color: var(--color-input-border) !important; // force override default button styles
  border-radius: var(--border-radius);
  color: var(--Header-button-color) !important; // force override default button styles
  cursor: pointer;
  line-height: 1.4;
  margin: var(--sm) var(--md);
  order: -1;
  padding: var(--xs) var(--sm);
  transition: var(--transition-slow);
  user-select: none;
  width: 100%;

  &:global(.Button) {
    overflow: hidden;

    > :global(.IconWrapper) {
      min-width: 16px; // avoid icon shrinkage on pages with long names when Owlbot is expanded
    }

    > span:not(:global(.IconWrapper)) {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  body:global(.theme-solid) & {
    background: var(--Header-border-color);
    border-color: var(--Header-border-color) !important; // force override default button styles
  }

  > * {
    margin-left: 0;
  }

  @media (min-width: #{$ipad-mini-portrait + 1}) {
    display: none;
  }
}

.MobileFlyout {
  padding: $md;

  &-divider {
    background: rgba(black, 0.1);
    border: 0;
    box-shadow: 0 1px 0 rgba(white, 0.05);
    height: 1px;
  }

  &-logo {
    display: block;
    margin-top: $sm;
  }
}
