@import '~@core/styles/vars';

.RefinementList {
  color: var(--color-text-muted);
  list-style-type: none;
  margin: 10px 0 0;
  padding-left: 0;

  &-Col {
    // <details> element
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    min-width: 0; // prevent filter col from collapsing when results overflow
    overflow-y: auto;
    padding: 20px;

    @media (max-width: 700px) {
      border-top: 1px solid var(--color-border-default);
    }

    &[open] .RefinementList-Header {
      padding-bottom: 5px;

      &::before {
        transform: rotate(90deg);
      }
    }

    @media (min-width: 701px) {
      border-left: 1px solid var(--color-border-default);
      flex: 0 0 200px;

      summary {
        pointer-events: none !important;
      }
    }
  }

  &-Header {
    align-items: center;
    display: flex;
    font-weight: 500;
    outline: none;
    position: relative;

    @media (max-width: 700px) {
      cursor: pointer;
      padding-left: 20px;

      &::before {
        content: '▶';
        display: inline-block;
        font-size: 0.75em;
        left: 0;
        position: absolute;
        top: 3px;
        transition: transform 0.2s cubic-bezier(0.16, 1, 0.3, 1);
      }
    }

    &::marker {
      display: none;
    }

    &::-webkit-details-marker {
      display: none;
    }
  }

  &-Item + .RefinementList-Item {
    margin-top: 10px;
  }

  &-Button {
    align-self: flex-start;
    margin: 20px 0 20px -5px;
  }

  &-Toggle {
    width: 100%;
  }

  :global(.Toggle-label) {
    display: inline-block;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
