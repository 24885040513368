@import '@core/styles/vars';
@import '@core/styles/mixins/dark-mode';

.rm-SearchToggle {
  --SearchToggle-bg: var(--white);
  --SearchToggle-border-color: #{rgba(black, 0.1)};
  --SearchToggle-color: var(--color-text-minimum);

  @include dark-mode {
    --SearchToggle-bg: #{rgba(black, 0.5)};
    --SearchToggle-color: #{rgba(white, 0.75)};
  }

  align-items: center;
  background: var(--SearchToggle-bg);
  border: 0;
  border-radius: var(--border-radius-lg);
  box-shadow: 0 0 0 1px var(--SearchToggle-border-color);
  cursor: pointer;
  display: flex;
  height: 30px; // small buttons
  line-height: 30px;
  padding: 0 5px;
  transition: box-shadow var(--transition-fast) var(--transition-timing);
  width: 150px;

  .ThemeContext_light & {
    @media (max-width: $ipad-mini-portrait) {
      @include dark-mode {
        --SearchToggle-bg: #{rgba(black, 0.1)};
        --SearchToggle-color: var(--color-text-minimum);
      }
    }
  }

  .ThemeContext_line & {
    @include dark-mode {
      --SearchToggle-bg: var(--color-bg-page);
      --SearchToggle-border-color: #{rgba(white, 0.1)};
    }
  }

  .ThemeContext_light:not(.ThemeContext_line) & {
    @include dark-mode {
      --SearchToggle-bg: var(--white);
      --SearchToggle-color: var(--gray40);
    }
  }

  /* stylelint-disable-next-line no-descending-specificity */
  &:focus {
    box-shadow: 0 0 0 1px var(--Header-button-active), 0 0 0 4px var(--Header-button-focus);
    outline: 0;
  }

  &-icon {
    color: var(--SearchToggle-color);
    font-size: var(--icon-md);
    transform: translateY(-1px);
  }

  &-placeholder {
    color: var(--SearchToggle-color);
    font-size: 14px;
  }

  &-shortcut {
    color: var(--SearchToggle-color);
    font-family: var(--font-family);
    font-size: 11px;
    font-weight: var(--font-weight);
  }
}
