@import '~@core/styles/vars';
@import '~@core/styles/mixins/dark-mode';

.SearchBert {
  --Owlbot-bg: transparent;
  --Owlbot-border: var(--Header-border-color, rgba(0, 0, 0, 0.1));
  --Owlbot-color: var(--Header-button-color, white);
  --Owlbot-placeholder: rgba(var(--white-rgb), 0.75);
  --Owlbot-code-bg-rgb: 255, 255, 255;
  --Owlbot-width: 150px;

  @include dark-mode {
    --Owlbot-code-bg-rgb: 0, 0, 0;
  }

  $block: &;

  /* stylelint-disable order/order */
  :global(.ThemeContext_line) & {
    --Owlbot-placeholder: var(--color-text-minimum);

    @include dark-mode {
      --Owlbot-placeholder: rgba(var(--white-rgb), 0.75);
    }
  }

  :global(.ThemeContext_light):not(.ThemeContext_line) & {
    --Owlbot-placeholder: var(--color-text-minimum);
  }

  background: var(--Owlbot-bg);
  border: 1px solid var(--Owlbot-border);
  border-radius: $button-sm;
  color: var(--Owlbot-color);
  height: $button-sm;
  overflow: hidden;
  padding: 0 0 0 var(--sm);
  position: relative;
  transition: width var(--transition-slow) var(--transition-timing);
  width: var(--Owlbot-width);

  /* We WANT to declare all CSS vars at the top of the file */
  /* stylelint-enable order/order */
  /* stylelint-disable-next-line no-descending-specificity */
  &:focus-within {
    --Owlbot-width: 225px;

    @media (max-width: 500px) {
      --Owlbot-width: 125px;
    }

    #{$block}-Prompt {
      @media (max-width: 500px) {
        opacity: 1;
      }
    }

    #{$block}-shortcut {
      opacity: 0;
    }
  }

  &-icon {
    color: currentcolor;
    opacity: 0.5;

    @media (max-width: 500px) {
      opacity: 1;
    }

    &_spinner {
      --spinner-color: currentcolor;
    }
  }

  &-uppercase {
    text-transform: uppercase;
  }

  &-Prompt {
    background: none;
    border: 0;
    color: inherit;
    flex: 1;
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: var(--font-weight);
    padding: 0;

    @media (max-width: 500px) {
      opacity: 0;
      width: 100%;
    }

    // hide default search styles
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      appearance: none;
    }

    &::placeholder {
      color: var(--Owlbot-placeholder);
    }

    &:focus {
      outline: none;
    }
  }

  &-shortcut {
    align-items: center;
    border-radius: $button-xs;
    display: inline-flex;
    font-family: var(--font-family-mono);
    font-size: 10px;
    font-weight: var(--font-weight-bold);
    height: $button-xs;
    letter-spacing: 0.1em;
    opacity: 1;

    @media (max-width: 500px) {
      display: none;
    }

    &_input {
      backdrop-filter: blur(var(--sm));
      background: rgba(var(--Owlbot-code-bg-rgb), 0.1);
      padding: 0 var(--xs);
      position: absolute;
      right: var(--xs);
      transition: opacity var(--transition-fast) var(--transition-timing);
    }

    &-cmd {
      font-size: 12px;
    }
  }

  &-moreButton {
    align-items: baseline;
    display: flex;
    font-size: 11px;
  }

  &-owlbotContainer {
    background: rgba(var(--gray10-rgb), 0.85);
    border-radius: calc(var(--border-radius) - 1px);
    color-scheme: dark;
    margin: var(--xs) var(--xs) 0;
  }

  @media (max-width: 500px) {
    --Owlbot-width: 25px;
  }
}

// The menu
:global {
  .rm-OwlbotMenu {
    --Owlbot-ai-color: var(--white);
    --Owlbot-height: calc(100vh - 109px); // 91px (header height) - 8px (dropdown offset) - 10px (margin from bottom)
    --Owlbot-search-hover-rgb: 0, 0, 0;
    --Owlbot-search-icon: var(--gray70);
    --Owlbot-search-width: 350px;
    --Owlbot-support: var(--gray70);

    @include dark-mode {
      --Owlbot-search-hover-rgb: 255, 255, 255;
      --Owlbot-search-icon: var(--gray40);
    }

    $block: &;

    max-height: var(--Owlbot-height);
    max-width: unset;
    min-width: unset;
    overflow: auto;
    width: var(--Owlbot-search-width);

    @media (max-width: 500px) {
      --Owlbot-search-width: calc(100vw - var(--sm));
    }

    &-results {
      flex: none;
      padding: 0;

      &::after {
        display: none;
      }

      &-empty {
        min-height: 15px;
        padding: var(--sm) 15px;

        &::after {
          display: none;
        }

        &-description {
          font-size: 12px;
        }
      }

      &-scrollTo {
        display: none;
      }

      &-list {
        padding: 0 var(--xs);
      }

      &-result {
        border-radius: var(--border-radius);
        overflow: hidden;
        padding: var(--xs);

        // extra specific to override Search component
        &.rm-OwlbotMenu-results-result {
          margin-bottom: 0;
        }

        &:hover {
          background: rgba(var(--Owlbot-search-hover-rgb), 0.05);
        }

        &:active,
        &:focus {
          background: var(--color-link-background);
          color: var(--color-link-primary);
        }

        &-icon {
          color: var(--Owlbot-search-icon);
        }

        &-title {
          font-size: 13px;
        }

        &-excerpt {
          font-size: 12px;

          .ais-Snippet.ais-Snippet {
            margin-top: 2px;
          }

          .ais-Snippet-highlighted::after {
            height: 1px;
          }
        }

        &-project {
          display: none;
        }
      }
    }
  }

  // empty state
  .rm-SearchModal-empty {
    padding: var(--sm);

    &-text {
      font-size: 13px;
      font-weight: var(--font-weight-normal);
    }
  }
}
