@import '@core/styles/vars';

.SearchTabs {
  display: flex;
  flex: 0 0 auto;
  white-space: nowrap;

  // tabs
  :global {
    .Tabs-list {
      flex: 0 0 auto;
      -ms-overflow-style: none; // hidden in IE
      overflow-x: auto;
      padding-right: 20px;
      width: 100%;

      // override reset
      &.Tabs-list {
        padding-left: 20px;
      }
    }
  }

  &-tab {
    align-items: center;
    display: flex;

    :global(.icon) {
      font-size: var(--icon-md);
      height: var(--icon-md);
      margin-right: var(--xs);
      opacity: var(--SuperHubSearch-tab-icon-opacity);
      width: var(--icon-md);

      :global(.Tabs-listItem_active) & {
        opacity: 1;
      }
    }
  }
}
