@import '~@core/styles/vars.compat';
@import '~@core/styles/mixins/dark-mode';

.SearchPagination {
  border-top: 1px solid var(--AlgoliaSearch-pagination-border);
  display: flex !important;
  flex-direction: row-reverse;
  margin-top: auto; // force pagination to bottom
  padding-top: var(--md);
  position: relative;

  &-State,
  &-Button {
    font-size: 14px;
  }

  &-State {
    align-items: center;
    color: var(--AlgoliaSearch-pagination-color);
    display: flex;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    pointer-events: none;
    text-align: center;
  }

  &-Button {
    @dark-mode {
      color: var(--AlgoliaSearch-pagination-color);
    }
  }
}
