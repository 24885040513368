@import '@core/styles/vars';
@import '@core/styles/mixins/dark-mode';

.LandingPageHeader {
  font-size: var(--LandingPageHeader-font-size);
  margin-left: auto;
  margin-right: auto;
  max-width: 750px;
  padding: $xl $md;

  &-title {
    margin-bottom: $sm;
    margin-top: 0;

    // overriding styles from #ssr-main on hub-container.styl
    :global(#ssr-main header) & {
      color: var(--LandingPageHeader-heading-color, var(--LandingPageHeader-color));
      font-family: var(--font-family);
      font-size: 2em;
      font-weight: var(--font-weight-bold);
    }
  }

  // :is(div) is added to raise specificity here, so it's not overidden by .markdown-body styling
  &-text:is(div) {
    color: var(--LandingPageHeader-color);
    font-size: 1em;
    line-height: 1.4;
    padding-bottom: $md;

    a,
    p {
      color: inherit;
    }

    a {
      &:hover,
      &:active,
      &:focus {
        text-decoration: none;
      }

      &:focus {
        border-radius: var(--radius-sm);
        box-shadow: 0 0 0 1px var(--color-primary);
        outline: 0;
      }
    }
  }

  &-button {
    &_primary {
      background: var(--LandingPageHeader-button-primary-background);
      color: var(--LandingPageHeader-button-primary-color);

      &:hover {
        background: var(--LandingPageHeader-button-primary-background-hover);
        color: var(--LandingPageHeader-button-primary-color);
      }

      &:active,
      &:focus {
        background: var(--LandingPageHeader-button-primary-background-focus);
        box-shadow: 0 0 0 3px var(--LandingPageHeader-button-primary-background-25);
        color: var(--LandingPageHeader-button-primary-color);
      }
    }

    &_secondary {
      background: transparent;
      border: 1px solid transparent;
      color: var(--LandingPageHeader-button-secondary-color);

      &:hover {
        background: transparent;
        border-color: var(--LandingPageHeader-button-secondary-border-hover);
        color: var(--LandingPageHeader-button-secondary-color);
      }

      &:active,
      &:focus {
        background: transparent;
        border-color: var(--LandingPageHeader-button-secondary-border-focus);
        box-shadow: 0 0 0 3px var(--LandingPageHeader-button-secondary-shadow-hover);
        color: var(--LandingPageHeader-button-secondary-color);
      }
    }
  }

  :global {
    // Search
    .rm-SearchToggle {
      background: rgba(white, 0.15);
      border: var(--border-width) solid rgba(black, 0.05);
      box-shadow: inset 0 1px rgba(white, 0.2), 0 1px 2px rgba(black, 0.1);
      cursor: pointer;
      height: $button-md;
      padding-left: 15px;
      padding-right: $md;
      transition: box-shadow var(--transition-fast) var(--transition-timing);

      &:hover {
        border-color: rgba(black, 0.15);
      }

      &:active,
      &:focus {
        border-color: rgba(black, 0.2);
        box-shadow: 0 0 0 3px rgba(black, 0.1);
      }

      :global(.ThemeContext_line) &,
      :global(.ThemeContext_light) & {
        @include dark-mode {
          background: var(--color-input-background);
          border-color: var(--color-input-border);
          box-shadow: none;
        }

        background: rgba(black, 0.05);

        &:hover {
          @include dark-mode {
            border-color: var(--color-input-border-hover);
          }

          border-color: rgba(black, 0.1);
        }

        &:active,
        &:focus {
          @include dark-mode {
            background: none;
            border-color: var(--color-input-border-active);
            box-shadow: 0 0 0 3px var(--color-input-border-focus);
          }

          background: $white;
          border-color: $blue;
          box-shadow: 0 0 0 3px rgba($blue, 0.25);
        }
      }

      &-icon {
        margin-right: $xs;

        :global(.ThemeContext_line) &,
        :global(.ThemeContext_light) & {
          color: var(--color-text-default);
        }
      }

      &-placeholder {
        color: $white;
        font-size: 16px;
        font-weight: var(--font-weight-bold);

        :global(.ThemeContext_line) &,
        :global(.ThemeContext_light) & {
          @include dark-mode {
            color: var(--color-input-text);
          }

          color: var(--color-text-default);
        }
      }

      &-shortcut {
        display: none;
      }
    }
  }
}

:global {
  .rm-LandingPageHeader {
    // theme is light and not line
    --LandingPageHeader-font-size: 18px;
    --LandingPageHeader-color: var(--color-text-default);
    --LandingPageHeader-button-primary-color: var(--white);
    --LandingPageHeader-button-primary-background: var(--slate);
    --LandingPageHeader-button-primary-background-25: #{rgba($slate, 0.25)};
    --LandingPageHeader-button-primary-background-hover: #{darken($slate, 10%)};
    --LandingPageHeader-button-primary-background-focus: #{darken($slate, 20%)};
    --LandingPageHeader-button-secondary-color: var(--slate);
    --LandingPageHeader-button-secondary-border-hover: #{rgba(black, 0.1)};
    --LandingPageHeader-button-secondary-border-focus: var(--slate);
    --LandingPageHeader-button-secondary-shadow-hover: #{rgba($slate, 0.25)};

    .rm-SearchToggle {
      width: auto;
    }

    :global(.ThemeContext_line) & {
      --LandingPageHeader-button-primary-color: var(--color-link-text);
      --LandingPageHeader-button-primary-background: var(--color-link-primary);
      --LandingPageHeader-button-primary-background-25: var(--color-link-primary-alpha-25);
      --LandingPageHeader-button-primary-background-hover: var(--color-link-primary-darken-10);
      --LandingPageHeader-button-primary-background-focus: var(--color-link-primary-darken-20);
      --LandingPageHeader-button-secondary-color: var(--color-link-primary);
      --LandingPageHeader-button-secondary-border-focus: var(--color-link-primary);
      --LandingPageHeader-button-secondary-shadow-hover: var(--color-link-primary-alpha-25);
      --LandingPageHeader-heading-color: var(--color-primary);

      @include dark-mode {
        --LandingPageHeader-button-primary-color: var(--color-input-text);
        --LandingPageHeader-button-primary-background: var(--color-input-background);
        --LandingPageHeader-button-primary-background-25: var(--color-input-background);
        --LandingPageHeader-button-primary-background-hover: var(--color-input-border-hover);
        --LandingPageHeader-button-primary-background-focus: var(--color-input-border-hover);
        --LandingPageHeader-button-secondary-color: var(--color-input-text);
        --LandingPageHeader-button-secondary-border-hover: var(--color-input-border-hover);
        --LandingPageHeader-button-secondary-border-focus: var(--color-input-border-active);
        --LandingPageHeader-button-secondary-shadow-hover: var(--color-input-border-focus);
      }
    }

    :global(.ThemeContext_line.ThemeContext_dark) & {
      @include dark-mode {
        --LandingPageHeader-heading-color: var(--color-text-default);
      }
    }

    :global(.ThemeContext_line.ThemeContext_light) & {
      --LandingPageHeader-heading-color: var(--color-text-default);
    }

    :global(.ThemeContext_dark:not(.ThemeContext_line)) & {
      --LandingPageHeader-color: var(--white);
      --LandingPageHeader-button-primary-color: var(--color-primary);
      --LandingPageHeader-button-primary-background: var(--white);
      --LandingPageHeader-button-primary-background-25: #{rgba($white, 0.25)};
      --LandingPageHeader-button-primary-background-hover: #{darken($white, 2.5%)};
      --LandingPageHeader-button-primary-background-focus: #{darken($white, 5%)};
      --LandingPageHeader-button-secondary-color: var(--white);
      --LandingPageHeader-button-secondary-border-focus: #{rgba($black, 0.25)};
      --LandingPageHeader-button-secondary-shadow-hover: #{rgba($black, 0.08)};
    }
  }
}
