@import '~@core/styles/vars';

.SearchProjectsFilter {
  $block: &;

  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  min-width: 0; // prevent filter col from collapsing when results overflow
  overflow-y: auto;
  padding: 20px;

  @media (max-width: 700px) {
    border-top: 1px solid var(--color-border-default);
    flex: 0 0 auto;
    max-height: 200px;
  }

  &[open] #{$block}-Header {
    padding-bottom: 5px;

    &::before {
      transform: rotate(90deg);
    }
  }

  @media (min-width: 701px) {
    border-left: 1px solid var(--color-border-default);
    flex: 0 0 200px;

    summary {
      pointer-events: none !important;
    }
  }

  &-header {
    align-items: center;
    display: flex;
    font-size: 14px;
    font-weight: var(--font-weight-normal);
    position: relative;
  }

  &-input {
    background: none;
    border: 0;
    color: var(--color-text-default);
    font-family: var(--font-family);
    width: 100%;

    &::placeholder {
      color: var(--color-text-minimum);
    }

    &::-webkit-search-cancel-button {
      appearance: none;
    }

    &:focus {
      outline: 0;
    }

    &-label {
      padding-left: 2px;
      width: 100%;

      &-icon {
        color: var(--color-text-minimum);
        opacity: 0.5;
      }
    }
  }

  &-list {
    color: var(--color-text-muted);
    display: flex;
    flex-direction: column;
    gap: var(--sm);
    list-style-type: none;
    margin: var(--sm) 0 0;
    padding-left: 0;

    &-item + #{$block}-item {
      margin-top: 10px;
    }
  }

  &-empty {
    color: var(--color-text-muted);
    padding: var(--sm);
    text-align: center;
  }

  &-toggle {
    justify-content: flex-start;
    width: 100%;
  }

  :global(.Toggle-label) {
    display: inline-block;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
