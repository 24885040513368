@import '@core/styles/vars';
@import '@core/styles/mixins/dark-mode';

.SearchBox {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  margin: 20px;
  position: relative;

  &-input {
    @include dark-mode {
      color-scheme: dark;
    }

    flex: 1 1 auto;
    padding-left: 36px !important;

    &_loading[type='search'] {
      background-image: none;
    }

    // remove safari search padding
    &::-webkit-search-decoration {
      appearance: none;
    }
  }

  &-spinner {
    animation: spin 0.5s infinite linear;
    border: 2px solid #fff;
    border-color: transparent transparent currentcolor currentcolor;
    border-radius: 14px;
    bottom: 0;
    color: get(color 'chalk');
    content: '';
    display: block;
    height: 14px;
    left: 12px;
    margin-bottom: auto;
    margin-top: auto;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 14px;
  
    @keyframes spin {
      0% {
        transform: rotate(0);
      }
  
      100% {
        transform: rotate(1turn);
      }
    }
  }
}
